import vars from './vars.module.scss';

export const getColorFromSCSS = (name: string) => {
  const value = vars[name];

  if (!value) {
    throw new Error(`Color ${name} not found in SCSS variables`);
  }

  return value;
};
