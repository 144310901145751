import { useCallback } from 'react';

import { useIntersectionObserver } from 'usehooks-ts';

import { type RootAction, useAppDispatch } from '@/store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ElementType = keyof JSX.IntrinsicElements | React.ForwardRefExoticComponent<any>;

const withTrackAppearance = <T extends ElementType, P = React.ComponentProps<T>>({
  Component,
  componentPropToTrack,
  intersectionThreshold,
  isOmitPropToTrack = true,
  onAppearanceAction,
  oncePerPage,
}: {
  Component: T;
  componentPropToTrack?: keyof P;
  intersectionThreshold?: number;
  isOmitPropToTrack?: boolean;
  onAppearanceAction: (dataToTrack?: unknown[]) => RootAction;
  oncePerPage?: boolean;
}) => {
  const WrappedComponent = ({
    [componentPropToTrack ?? '']: dataToTrack,
    intersectionThreshold: intersectionThresholdDynamic,
    ...restProps
  }: React.PropsWithoutRef<P> & { intersectionThreshold?: number }) => {
    const dispatch = useAppDispatch();

    const trackEvent = useCallback(() => {
      if (dataToTrack == null) {
        if (onAppearanceAction) {
          dispatch(onAppearanceAction());
        }
        return;
      }

      const transformedToArray = Array.isArray(dataToTrack) ? dataToTrack : [dataToTrack];
      dispatch(onAppearanceAction(transformedToArray));
    }, [dataToTrack, dispatch]);

    const { ref } = useIntersectionObserver({
      freezeOnceVisible: oncePerPage,
      onChange: (isVisible) => isVisible && trackEvent?.(),
      threshold: intersectionThreshold ?? intersectionThresholdDynamic,
    });

    const props = isOmitPropToTrack
      ? restProps
      : {
          [componentPropToTrack ?? '']: dataToTrack,
          ...restProps,
        };

    // @ts-ignore
    return <Component {...props} ref={ref} />;
  };

  WrappedComponent.displayName = `withTrackAppearance(${Component.displayName || Component.name || 'Component'})`;
  return WrappedComponent;
};

export default withTrackAppearance;
