import getSymbolFromCurrenciesData from './currency/currencySymbols';

export const enum Fraction {
  NOT_INT = 'not-int', // default
  NOT_ZERO = 'not-zero',
  ALWAYS = 'always',
}

export type TFormatMoneyOptions = {
  fraction?: Fraction;
  onlySymbol?: boolean;
  roundUp?: boolean;
};

const formatMoney = (amount: number, currencyCode?: string, options?: TFormatMoneyOptions): string => {
  if (!currencyCode) {
    console.error('Currency code is not provided');
    return '—';
  }

  const { fraction, onlySymbol, roundUp } = options || {};
  const asInteger =
    (!amount && fraction === Fraction.NOT_ZERO) ||
    (Number.isInteger(amount) && fraction !== Fraction.ALWAYS) ||
    roundUp;
  const fractionDigits = asInteger ? 0 : 2;

  let price = amount;
  if (roundUp) {
    price = Math.ceil(price);
  }

  return [
    amount < 0 ? '-' : '',
    getSymbolFromCurrenciesData(currencyCode, { onlySymbol }),
    Math.abs(price).toLocaleString('en-US', {
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits,
    }),
  ].join('');
};

export default formatMoney;
