import { getColorFromSCSS } from '@/helpers/styles/getColorFromSCSS';

// correlates with _variables.scss
export const SKELETON_COLOR_SCHEMES = {
  ['gray-216-gray-100']: {
    baseColor: getColorFromSCSS('gray-light-2'),
    highlightColor: getColorFromSCSS('gray-216'),
  },
  ['gray-216-gray-410']: {
    baseColor: getColorFromSCSS('gray-216'),
    highlightColor: getColorFromSCSS('gray-410'),
  },
  purple: {
    baseColor: getColorFromSCSS('black-violet'),
    highlightColor: getColorFromSCSS('purple-02'),
  },
};
