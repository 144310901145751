import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/_common/EarlyProviders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TransferTokens","TransferToStoreClient"] */ "/app/src/app/_common/TransferData.client.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/UIResource/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Headline"] */ "/app/src/features/promo/Headline/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QuickSearchCards"] */ "/app/src/features/promo/QuickSearchCards/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/tagmanager/event/watchers.js");
